<template>
  <!-- 工厂下拉列表 -->
  <!-- filterable
             :filter-method="getList" -->
  <el-select
    v-model="val"
    size="mini"
    @change="onChange"
    @visible-change="getList"
  >
    <el-option value="" label="全部" v-if="isAll"></el-option>
    <el-option
      v-for="item in list"
      :value="item.id"
      :label="item.name"
      :key="item.id"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    isAll: {
      type: Boolean,
      default: true,
    },
    clinicId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      val: "", //
      list: [],
      disabled: false,
    };
  },
  async mounted() {
    await this.getList("");
    this.val = this.list[0].id || "";
    this.$emit("defaultChang", this.val);
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    async getList(keyword) {
      // this.disabled=false
      if (keyword === false) return;
      let params = {
        page_size: 10,
        current_page: 1,
        clinic_id: this.clinicId,
        keyword: keyword == true ? "" : keyword,
      };
      return this.$axios
        .post("/setting/factory/getClinicFactoryList", params)
        .then((res) => {
          if (res.code == 1) {
            this.list = res.data.list;
            // 如果列表中不存在对应工厂id时，清空当前设置的工厂id
            if (this.list.findIndex((item) => item.id == this.val) == -1) {
              this.val = "";
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
  watch: {
    value(v, ov) {
      if (v != ov) {
        this.val = this.value;
      }
    },
  },
};
</script>
